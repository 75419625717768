<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          v-show="false"
          is-blur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>
  <main class="main-content main-content-bg mt-0">
    <div class="page-header min-vh-100" :style="styleBGImage">
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7 mt-2">
            <div v-if="formLogin">
              <div class="card border-0 mb-0">
                <div class="card-header bg-transparent">
                  <div class="text-center">
                    <argon-alert color="danger" v-show="showAlert">
                      <small>Error! User not found!</small>
                    </argon-alert>
                  </div>
                  <h5 class="text-dark text-center mt-2 mb-3">Login</h5>
                  <div class="btn-wrapper text-center">
                    <a
                      v-show="false"
                      href="javascript:;"
                      class="btn btn-neutral btn-icon btn-sm mb-0 me-1"
                    >
                      <img
                        class="w-30"
                        src="../../../assets/img/logos/github.svg"
                      />
                      Github
                    </a>
                    <a
                      v-show="false"
                      href="javascript:;"
                      class="btn btn-neutral btn-icon btn-sm mb-0"
                    >
                      <img
                        class="w-30"
                        src="../../../assets/img/logos/google.svg"
                      />
                      Google
                    </a>
                    <div class="btn btn-neutral btn-icon btn-sm mb-0">
                      <div
                        id="buttonDiv"
                        @click.prevent="handleGoogleLogin"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="card-body px-lg-5 pt-0">
                  <div class="text-center text-muted mb-4">
                    <small>Or sign in with credentials</small>
                  </div>
                  <form class="text-start" @submit.prevent="onSubmit">
                    <div class="mb-3">
                      <argon-input
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Email"
                        aria-label="Email"
                        :value="form.email"
                      />
                    </div>
                    <div class="mb-3">
                      <argon-input
                        id="password"
                        type="password"
                        name="password"
                        placeholder="Password"
                        aria-label="Password"
                        :value="form.password"
                      />
                    </div>

                    <div class="text-center">
                      <div class="d-grid gap-2">
                        <!--
                        <input type="submit" class="btn btn-success" value="Submit">
                        -->
                      </div>
                      <argon-button
                        color="success"
                        variant="gradient"
                        full-width
                        class="my-4 mb-2"
                        :loading="isLoading"
                        >{{ labelSubmit }}</argon-button
                      >
                    </div>
                    <div v-show="true" class="mb-2 position-relative text-center">
                      <p
                        class="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3"
                      >
                        or
                      </p>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div v-show="true" class="text-center d-grid gap-2">
                          <b-link class="btn btn-block btn-dark mt-2 mb-4" @click.prevent="handleSignup">Sign Up</b-link>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div v-show="true" class="text-center d-grid gap-2">
                          <b-link class="btn btn-warning mt-2 mb-4" @click.prevent="handleRecovery">Problem?</b-link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer v-show="true" />
</template>

<script>
import axios from "axios";
//import Swal from "sweetalert2";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapMutations } from "vuex";
import ArgonAlert from "@/components/ArgonAlert.vue";

export default {
  name: "Login",
  components: {
    Navbar,
    AppFooter,
    ArgonInput,
    ArgonButton,
    ArgonAlert
  },
  data() {
    return {
      urllogin: "/api/v1/login",
      urlreloadlogin: "/api/v1/reloadlogin",
      loaderShow: false,
      labelSubmit: "Sign in",
      isLoading: false,
      formLogin:true,
      form: {
        email: "",
        password: "",
      },
      baseurl: axios.defaults.baseURL,
      //styleBGImage: 'background-image: url("./assets/img/signin-basic.jpg");',
      styleBGImage:
        "background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-basic.jpg');",
      showAlert: false,
    };
  },
  mounted() {
    console.log("mounted");
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    this.checkAuth();
    //console.log(this.$hostname);
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    async onSubmit(values) {
      //console.log(values.target["email"].value);
      //console.log(values.target["password"].value);
      this.isLoading = true;
      try {
        const response = await axios({
          method: "post",
          url: this.urllogin,
          data: {
            email: values.target["email"].value,
            password: values.target["password"].value,
          },
        });
        if (response.data.status == "error") {
          this.isLoading = false;
          console.log(response.data.message);
        } else if (response.data.status == "success") {
          this.isLoading = true;
          // save response
          let dataAuth = JSON.stringify(response.data.auth);
          localStorage.setItem("token", response.data.access_token);
          localStorage.setItem("refresh_token", response.data.refresh_token);
          localStorage.setItem("auth", dataAuth);

          //redirect
          window.location.href = this.$hostname + "/dashboards";
          //this.$router.push({ path: "/dashboards" });
        }
      } catch (error) {
        this.isLoading = false;
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false;
        }, 5000);
        console.log(error.response.data.message);
        this.errorRedirect500(error);
      }
    },
    checkAuth() {
      const token = localStorage.getItem("token");
      const refresh_token = localStorage.getItem("refresh_token");
      const auth = localStorage.getItem("auth");
      if (token != "" && refresh_token != "" && auth != "") {
        this.reloadLogin();
      } else {
        this.clearAuth();
      }
    },
    async reloadLogin() {
      try {
        this.isLoading = true;

        const response = await axios({
          method: "get",
          url: this.urlreloadlogin,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("refresh_token"),
          },
        });
        //console.log(response.data)
        if (response.data.status == "success") {
          //console.log(response.data);
          // get new token
          localStorage.setItem("token", response.data.access_token);
          localStorage.setItem("refresh_token", response.data.refresh_token);
          let dataAuth = JSON.stringify(response.data.auth);
          localStorage.setItem("auth", dataAuth);

          // close swal
          //Swal.close();

          //redirect
          //this.$router.push({ path: "/dashboards" });
          window.location.href = this.$hostname + "/dashboards";
        } else {
          this.clearAuth();
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
        this.clearAuth();
      }
    },
    clearAuth() {
      localStorage.setItem("token", "");
      localStorage.setItem("refresh_token", "");
      localStorage.setItem("google_token", "");
      localStorage.setItem("auth", "");
      localStorage.setItem("user", "");
      localStorage.setItem("payload", "");
    },
    handleGoogleLogin() {
      console.log("handleLogin");
    },
    handleRecovery() {
      this.$router.push({ path: "/authentication/reset/password" });
    },
    handleSignup() {
      this.$router.push({ path: "/authentication/signup/register_for_member" });
    },
    errorRedirect500(error) {
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
  },
};
</script>
