<template>
  <footer class="py-5 footer">
    <div class="container">
      <div class="row">
        <div class="mx-auto mb-4 text-center col-lg-8">
          <a
            href="https://inputorder.com/"
            target="_blank"
            class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0"
          >
            Home
          </a>
          <a
            href="https://inputorder.com/build-for-u/"
            target="_blank"
            class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0"
          >
            Build for You
          </a>
          <a
            href="https://inputorder.com/join-partner/"
            target="_blank"
            class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0"
          >
            Join Partner
          </a>
          <a
            href="https://inputorder.com/tutorial/"
            target="_blank"
            class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0"
          >
            Tutorial
          </a>
          <!-- <a
              href="javascript:;"
              target="_blank"
              class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0"
            >
              Blog
            </a>
            <a
              href="javascript:;"
              target="_blank"
              class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0"
            >
              Pricing
            </a> -->
        </div>
        <div class="mx-auto mt-2 mb-4 text-center col-lg-8" v-show="false">
          <a
            href="javascript:;"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-dribbble"></span>
          </a>
          <a
            href="javascript:;"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-twitter"></span>
          </a>
          <a
            href="javascript:;"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-instagram"></span>
          </a>
          <a
            href="javascript:;"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-pinterest"></span>
          </a>
          <a
            href="javascript:;"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-github"></span>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="mx-auto mt-1 text-center col-8">
          <p class="mb-0 text-secondary">
            <small style="font-size:14px;color:#bbbbbb;"><i>
            © InputOrder {{ version }}, last updated at {{ updatedAt }},<br />made
            with <i class="fa fa-heart"></i> by
            <a
              href="https://www.youtube.com/c/YutubnyaBangSyaiha"
              class="font-weight-bold"
              >bangsyaiha</a
            >
            for a better imers.</i>
        </small>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      version: "",
      updatedAt: "",
    };
  },
  created() {
    this.getAppVersion();
  },
  methods: {
    getAppVersion() {
      this.version = this.$appVersion;
      this.updatedAt = this.$appUpdatedAt;
    },
  },
};
</script>
