<template>
  <div class="container position-sticky z-index-sticky top-0">
    <navbar
      v-show="false"
      :dark-mode="true"
      is-blur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
    />
  </div>
  <main class="main-content mt-0">
    <div
      class="page-header min-vh-100"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/illustrations/404.svg') + ')',
      }"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-7 mx-auto text-center">
            <img class="w-30" src="../../../assets/img/logos/google.svg" />
            <h2>Google auth processing...</h2>
            <p class="lead">
              Please wait, soon you will be directed to the dashboard page after
              completing the google verification.
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
import axios from "axios";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import { mapMutations } from "vuex";
export default {
  name: "GoogleAuth",
  components: {
    Navbar,
    AppFooter,
  },
  data() {
    return {
        urllogin: "/api/v1/googleauth",
        token: '',
    }
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    document.getElementsByTagName("body")[0].classList.remove("bg-gray-100");
    this.checkGoogleToken();
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    checkGoogleToken() {
      const googleToken = localStorage.getItem("google_token");
      if (googleToken) {
        this.token = googleToken;
        this.googleLogin();
      }
    },
    async googleLogin() {
      try {
        const response = await axios({
          method: "post",
          url: this.urllogin,
          data: {
            token: this.token,
          },
        });
        if (response.data.status == "error") {
            //this.$router.push({ path: "/login" });
            window.location.href = this.$hostname + "/login";
        } else if (response.data.status == "success") {
          // save response
          let dataAuth = JSON.stringify(response.data.auth);
          localStorage.setItem("token", response.data.access_token);
          localStorage.setItem("refresh_token", response.data.refresh_token);
          localStorage.setItem("auth", dataAuth);

          // clear google token
          localStorage.setItem("google_token", "");

          //redirect
          //window.location.href = this.$hostname + "/dashboards";
          this.$router.push({ path: "/dashboards" });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
