<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          v-show="false"
          is-blur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>

  <main class="main-content main-content-bg mt-0">
    <div
      class="page-header min-vh-100"
      style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/reset-basic.jpg');
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7">
            <div class="card z-index-0 mb-7">
              <div class="card-header text-center pt-4 pb-1">
                <h4 class="font-weight-bolder mb-1">Reset password</h4>
                <p class="mb-0">
                  You will receive an e-mail in maximum 60 seconds
                </p>
              </div>
              <div class="card-body">
                <form role="form" @submit.prevent="handleSubmit">
                  <argon-input
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Email"
                    aria-label="Email"
                  />
                  <div class="text-center">
                    <argon-alert
                      v-if="showErrorMessage"
                      color="danger"
                      style="font-size: 12px"
                      >{{ errorMessage }}</argon-alert
                    >
                    <argon-alert
                      v-if="showSuccessMessage"
                      color="primary"
                      style="font-size: 14px"
                      >{{ successMessage }}</argon-alert
                    >
                    <argon-button
                      color="dark"
                      variant="gradient"
                      class="my-4 mb-2"
                      :loading="isLoading"
                      full-width
                      >Send</argon-button
                    >
                  </div>
                </form>
                <div
                  v-show="true"
                  class="mb-2 mt-4 position-relative text-center"
                >
                  <p
                    class="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3"
                  >
                    or
                  </p>
                </div>
                <div class="text-center mt-4">
                  <argon-button
                    color="dark"
                    variant="outline"
                    :loading="isLoadingBackToLogin"
                    @click.prevent="handleToLogin"
                    >Back to Login</argon-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer v-show="false"/>
</template>

<script>
import axios from "axios";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonAlert from "@/components/ArgonAlert.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";

export default {
  name: "ResetPassword",
  components: {
    Navbar,
    AppFooter,
    ArgonInput,
    ArgonButton,
    ArgonAlert,
  },
  data() {
    return {
      urlPasswordReset: "/api/v1/password_reset",
      isLoading: false,
      isLoadingBackToLogin: false,
      form: {
        email: "",
      },
      errorMessage: "",
      successMessage: "",
      showErrorMessage: false,
      showSuccessMessage: false,
    };
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    body.classList.remove("bg-gray-200");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
    body.classList.add("bg-gray-200");
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    async handleSubmit(values) {
      try {
        this.showErrorMessage = false;
        this.showSuccessMessage = false;
        this.isLoading = true;

        const response = await axios({
          method: "post",
          url: this.urlPasswordReset,
          data: {
            email: values.target["email"].value,
          },
        });
        this.isLoading = false;
        if (response.data.status == "success") {
          console.log("success");
          this.successMessage = response.data.message;
          this.showSuccessMessage = true;
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
        if(error.response.status == 404) {
          this.errorMessage = error.response.data.message;
        }else{
          this.errorMessage = 'ERROR! Something went wrong!';
        }
        
        this.showErrorMessage = true;
      }
    },
    handleToLogin() {
      //this.$router.push({ path: "/login" });
      this.isLoadingBackToLogin = true;
      window.location.href = this.$hostname + "/login";
    },
    errorRedirect500(error) {
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
  },
};
</script>
