<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-12 mb-lg-0">
          <div
            class="text-sm text-center copyright text-secondary"
            :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
          ><i>© InputOrder {{ version }}, last updated at {{ updatedAt }}, made with
            <i class="fa fa-heart"></i> by
            <a
              href="https://www.youtube.com/c/YutubnyaBangSyaiha"
              class="font-weight-bold"
              >bangsyaiha</a
            >
            for a better imers.</i>
          </div>
        </div>
        <div v-show="false" class="col-lg-6">
          <ul
            v-show="false"
            class="nav nav-footer justify-content-center justify-content-lg-end"
          >
            <li class="nav-item">
              <a
                href="https://www.creative-tim.com"
                class="nav-link text-muted"
                target="_blank"
                >Creative Tim</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://www.creative-tim.com/presentation"
                class="nav-link text-muted"
                target="_blank"
                >About Us</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://www.creative-tim.com/blog"
                class="nav-link text-muted"
                target="_blank"
                >Blog</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://www.creative-tim.com/license"
                class="nav-link pe-0 text-muted"
                target="_blank"
                >License</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
//import { ref } from "vue";

export default {
  name: "AppFooter",
  data() {
    return {
      version: '',
      updatedAt: '',
    }
  },
  created() {
    this.getAppVersion();
  },
  methods: {
    getAppVersion() {
      this.version = this.$appVersion;
      this.updatedAt = this.$appUpdatedAt;
    }
  }
  
  // setup() {
  //   const version = ref("Beta v.0.1.6");
  //   const updatedAt = ref("19 Maret 2023");

  //   // expose to template and other options API hooks
  //   return { version,updatedAt };
  // },
};
</script>
