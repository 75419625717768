<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          v-show="false"
          is-blur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>
  <main class="main-content mt-0">
    <section>
      <div class="page-header min-vh-100" style="background-image: url('https://inputorder.com/wp-content/uploads/2023/01/track-progress-illustration-1.jpg');background-position: -100px 0px 0px 0px;">
        <div class="container">
          <div class="row">
            <div
              class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto"
            >
              <div v-if="showIntroduction" class="card card-plain">
                <div class="card-body pb-0 text-center">
                  <img
                    class="w-60 mx-auto"
                    src="../../../assets/img/logo-inputorder.png"
                    alt="sidebar_illustration"
                  />
                  <p class="text-secondary" style="margin-bottom:100px;"><i>"..Keep on track. Reach your selling goal. Track your progress with our app..""</i></p>
                  <argon-button
                    color="warning"
                    variant="gradient"
                    full-width
                    class="mt-4 mb-0"
                    @click.prevent="handleShowFormRegister"
                    >CREATE ACCOUNT</argon-button
                  >
                </div>
                <div class="card-footer text-center pt-0 px-sm-4 px-1">
                  <p class="text-sm mt-4 mx-auto text-secondary">
                    Already have an account?<br />
                    <a
                      href="#"
                      class="text-warning font-weight-bolder"
                      @click.prevent="handleToLogin"
                    >
                      Sign in Here &#8594;
                    </a>
                  </p>
                </div>
              </div>
              <div v-if="showRegisterForm" class="card card-plain">
                <div class="card-header pb-0 text-left">
                  <h4 class="font-weight-bolder">Sign Up</h4>
                  <p class="mb-0">Enter your email and password to register</p>
                </div>
                <div class="card-body pb-3">
                  <argon-alert
                    v-if="showErrorMessage"
                    color="danger"
                    style="font-size: 14px"
                    >{{ errorMessage }}</argon-alert
                  >
                  <argon-alert
                    v-if="showSuccessMessage"
                    color="primary"
                    style="font-size: 14px"
                    >{{ successMessage }}</argon-alert
                  >
                  <form role="form" @submit.prevent="onSubmit">
                    <label>Name</label>
                    <argon-input
                      id="name"
                      name="fullname"
                      type="text"
                      placeholder="Name"
                      aria-label="Name"
                    />
                    <label>Email</label>
                    <argon-input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      aria-label="Email"
                    />
                    <label>Password</label>
                    <argon-input
                      id="password"
                      name="password"
                      type="password"
                      placeholder="Password"
                      aria-label="Password"
                    />
                    <argon-checkbox
                      :checked="form.checked"
                      @click="handleCheckTermCondition"
                    >
                      <label
                        class="form-check-label"
                        for="flexCheckDefault"
                        checked
                      >
                        I agree the
                        <a href="#" class="text-dark font-weight-bolder"
                          >Terms and Conditions</a
                        >
                      </label>
                    </argon-checkbox>
                    <div class="text-center">
                      <argon-button
                        v-if="showButtonRegister"
                        color="success"
                        variant="gradient"
                        full-width
                        class="mt-4 mb-0"
                        :disabled="disabledButton"
                        :loading="isLoading"
                        >Sign up</argon-button
                      >
                    </div>
                  </form>
                </div>
                <div class="card-footer text-center pt-0 px-sm-4 px-1">
                  <p class="mb-4 mx-auto">
                    Already have an account?
                    <a
                      href="#"
                      class="text-success font-weight-bolder"
                      @click.prevent="handleToLogin"
                    >
                      Sign in
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column"
            >
              <div class="position-relative bg-gradient-success h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-ill.jpg');
                  background-size: cover;
                "
              >
                <span class="mask bg-success opacity-4"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >
                  Your journey starts here
                </h4>
                <p class="text-white position-relative">
                  {{ words_of_wisdom_selected }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonAlert from "@/components/ArgonAlert.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";

export default {
  name: "RegisterForMember",
  components: {
    Navbar,
    ArgonInput,
    ArgonCheckbox,
    ArgonButton,
    ArgonAlert,
  },
  data() {
    return {
      urlregister: "/api/v1/register",
      isLoadingBackToLogin: false,
      showButtonRegister: true,
      disabledButton: "disabled",
      isLoading: false,
      showSuccessMessage: false,
      showErrorMessage: false,
      errorMessage: "",
      successMessage: "",
      showIntroduction: true,
      showRegisterForm: false,
      form: {
        fullname: "",
        email: "",
        checked: false,
      },
      words_of_wisdom_selected: "",
      words_of_wisdom: [
        '"Pemasaran bukan lagi tentang barang yang kamu buat, tetapi tentang cerita yang kamu ceritakan." - Seth Godin',
        '"Merek hanyalah persepsi, dan persepsi akan sesuai dengan kenyataan dari waktu ke waktu." - Elon Musk',
        '"Konten adalah api; media sosial adalah bensin." - Jay Baer',
        '"Pemasar perlu membangun hubungan dan reputasi digital sebelum menutup penjualan." - Chris Brogan',
        '"Orang tidak membeli apa yang kamu lakukan, mereka membeli mengapa kamu melakukannya." - Simon Sinek',
        '"Konten bukanlah raja, tetapi seorang presiden yang dipilih oleh suara dari mereka yang ingin diperintah." - Raheel Farooq',
        '"Ambil risiko dan teruslah menguji karena apa yang berhasil hari ini tidak akan berhasil besok, tetapi apa yang berhasil kemarin mungkin berhasil lagi." - Amrita Sahasrabudhe',
        '"Tugas kita sebagai pemasar adalah memahami bagaimana pelanggan ingin membeli dan membantu mereka melakukannya." - Bryan Eisenberg',
        '"Bisnis hanya memiliki dua fungsi - pemasaran dan inovasi." - Milan Kundera',
        '"Orang akan mengabaikan atau melewatkan apa pun yang tidak mereka sukai. Jadi brand harus mulai membuat hal-hal yang mereka sukai." - Steve Pratt',
      ],
    };
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    body.classList.remove("bg-gray-100");
    this.randomWordsOfWisdom();
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),

    randomWordsOfWisdom() {
      let words = this.words_of_wisdom;
      // let min = 0;
      // let max = words.length;
      // Returns a random integer from 0 to 9:
      let numberOfRandom = Math.floor(Math.random() * 10);
      this.words_of_wisdom_selected = words[numberOfRandom];
    },
    handleToLogin() {
      //this.$router.push({ path: "/login" });
      this.isLoadingBackToLogin = true;
      window.location.href = this.$hostname + "/login";
    },
    handleCheckTermCondition(values) {
      this.form.checked = values.target.checked;
      if (this.form.checked == true) {
        this.disabledButton = "";
      } else {
        this.disabledButton = "disabled";
      }
      // this.showButtonRegister = false;
      // setTimeout(() => this.showButtonRegister = true, 1);
    },
    async onSubmit(values) {
      this.isLoading = true;
      this.showErrorMessage = false;
      try {
        const response = await axios({
          method: "post",
          url: this.urlregister,
          data: {
            fullname: values.target["fullname"].value,
            email: values.target["email"].value,
            password: values.target["password"].value,
          },
        });
        if (response.data.status == "error") {
          this.isLoading = false;
          console.log(response.data.message);
          this.errorMessage = response.data.message;
          this.showErrorMessage = true;
        } else if (response.data.status == "success") {
          this.isLoading = true;
          this.successMessage = response.data.message;
          this.showSuccessMessage = true;

          //redirect
          window.location.href = this.$hostname + "/login";
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    handleShowFormRegister() {
      this.showIntroduction = false;
      this.showRegisterForm = true;
    },
  },
};
</script>
