<template>
  <div>
    <div class="cardt">
      <div class="cardt-body">
        <h5 v-if="display_label">{{ label }} ({{ type }})</h5>
        <p class="text-muted text-sm">{{ formatinfo }}<br />{{ sizeinfo }}</p>
        <div v-if="loadingFile" class="mt-3">Loading file...</div>
        <span v-if="display_file_after_upload">
          <div v-if="anyFile">
            <div v-if="accept == 'application/pdf'">
              <i class="fa fa-file-pdf fa-5x text-danger"></i><br /><a
                :href="urlFile"
                >{{ filename }}</a
              >
            </div>
            <div v-if="accept == 'image/jpeg, image/png, image/gif'">
              <img
                :src="urlFile"
                class="img-thumbnail img-fluid"
                style="height: 200px"
                :alt="type"
              />
            </div>
          </div>
          <div v-if="noFile" class="text-center">
            <div class="card">
              <div class="card-body">
                <br />
                <i class="fas fa-camera fa-5x text-muted"></i>
                <br /><br />
              </div>
            </div>
          </div>
        </span>
        <div class="form-group mt-3">
          <input
            type="file"
            class="form-control"
            :accept="accept"
            @change="onFileSelected"
          />
        </div>

        &nbsp;&nbsp;<small class="text-muted"
          ><i
            >{{ uploadProgressFile }}
            <span class="text-success">{{ checkListSuccess }}</span></i
          ></small
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { ElLoading } from "element-plus";

export default {
  name: "GenUpload",
  props: {
    label: { required: true, type: String },
    formatinfo: { required: true, type: String },
    sizeinfo: { required: true, type: String },
    type: { required: true, type: String },
    accept: { required: true, type: String },
    url_upload: { required: true, type: String },
    url_read: { required: true, type: String },
    pathfolder: { required: true, type: String },
    display_file_after_upload: {
      required: false,
      type: Boolean,
      default: true,
    },
    display_label: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      token: "",
      dataAuth: "",
      selectedFile: null,
      loadingFile: false,
      anyFile: false,
      noFile: false,
      uploadProgressFile: "",
      filename: "",
      urlFile: "",
      basePath: axios.defaults.baseURL,
      basePathFolderImage: "/uploads",
      mainProps: { width: 300, class: "m1" },
      checkListSuccess: "",
      uploadcallback: false,
      responseimagecallback: [],
    };
  },
  created() {
    this.getDefaultVar();
    this.getDataUpload();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },
    getDefaultVar() {
      this.token = localStorage.getItem("token");
    },
    async getDataUpload() {
      if (this.url_read) {
        this.loadingFile = true;
        //console.log('GetDataUpload: '+this.url_read);

        try {
          const response = await axios({
            method: "get",
            url: this.url_read,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });
          //console.log(response.data.fileread[this.type]);
          this.loadingFile = false;

          if (response.data.status == "success") {
            if (response.data.fileread == null) {
              this.loadingFile = false;
              this.anyFile = false;
              this.noFile = true;
            } else {
              let fileread = response.data.fileread[this.type];
              let typeRegDoc = this.type.toLowerCase();
              console.log(typeRegDoc);
              if (fileread != undefined) {
                this.anyFile = true;
                this.noFile = false;

                let filename = fileread;
                this.filename = filename;

                // check pathfolder
                if (response.data.fileread.path != undefined) {
                  this.urlFile =
                    this.basePath +
                    response.data.fileread.path +
                    "/" +
                    filename;
                } else {
                  this.urlFile =
                    this.basePath +
                    this.basePathFolderImage +
                    this.pathfolder +
                    "/" +
                    filename;
                }

                //console.log(this.urlFile);
              }
            }
          } else {
            this.loadingFile = false;
            this.anyFile = false;
            this.noFile = true;
          }
        } catch (error) {
          console.log(error);
          this.loadingFile = false;
        }
      }
    },
    onFileSelected(event) {
      this.selectedFile = event.target.files[0];
      //console.log(this.selectedFile);
      this.onUpload();
    },
    onUpload() {
      this.loadingService();
      const fd = new FormData();
      fd.append("file_upload", this.selectedFile);
      fd.append("type", this.type);
      axios
        .post(this.url_upload, fd, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          onUploadProgress: (uploadEvent) => {
            this.uploadProgressFile =
              Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
            console.log("Upload Progress: " + this.uploadProgressFile);
          },
        })
        .then(
          (res) => {
            //console.log(res);
            this.loadingService().close();

            //Swal.fire("Good job!", "Data berhasil disimpan!", "success");
            if (res.data.status == "400") {
              Swal.fire({
                icon: "warning",
                title: "Warning!",
                text: res.data.message,
              });
            } else if (res.data.status == "error") {
              Swal.fire({
                icon: "warning",
                title: "Warning!",
                text: res.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Good job!",
                text: "Data berhasil upload!",
                timer: 1500,
              });

              this.checkListSuccess = "Successfully uploaded..";
              this.getDataUpload();

              this.$emit("successuploadcallback", true);
            }

            this.$emit("uploadcallback", false);
            setTimeout(() => {
              this.$emit("uploadcallback", true);
            }, 1000);

            // response image callback
            if (res.data.image != undefined) {
              let image = res.data.image;
              this.$emit("responseimagecallback", image);
            }

            //console.log(fd);
          },
          (err) => {
            this.loadingService().close();
            Swal.fire("Error!", "Error upload!, " + err.message, "error");
            this.uploadProgressFile = "";
          }
        );
    },
  },
};
</script>

<style></style>
