<template>
  <div class="container position-sticky z-index-sticky top-0">
    <div class="row">
      <div class="col-12"></div>
    </div>
  </div>
  <main class="main-content main-content-bg mt-0">
    <div class="page-header min-vh-100" :style="styleBg">
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7">
            <div class="card py-lg-3">
              <div class="card-body text-center">
                <h4 class="mb-0 font-weight-bolder">Hi!, {{ helperName }}</h4>
                <small class="text-secondary">{{ managerName }}</small>
                <div v-if="unlockForm">
                  <p class="mb-4">{{ desc }}</p>
                  <form role="form" @submit.prevent="onSubmit">
                    <div class="row">
                      <div class="col-md-12">
                        <input
                          v-model="form.key"
                          type="text"
                          class="form-control form-control-lg"
                          style="text-align: center"
                          required
                        />
                      </div>
                    </div>
                    <div class="text-center mt-3">
                      <argon-button
                        size="lg"
                        full-width
                        color="dark"
                        variant="gradient"
                        class="mb-0"
                        :loading="isLoading"
                        >Unlock</argon-button
                      >
                    </div>
                  </form>
                </div>
                <div v-if="createOrderForm">
                  <p class="mb-4 text-sm">{{ descCreateOrder }}</p>
                  <div>
                    <GenUpload
                      v-if="uploadFormImport"
                      style="margin-top: -30px; margin-bottom: -40px"
                      :display_label="upload.display_label"
                      :display_file_after_upload="
                        upload.display_file_after_upload
                      "
                      :accept="upload.accept"
                      :url_upload="upload.urlUpload"
                      pathfolder="/import"
                      type="csv"
                      @uploadcallback="getUploadCallback"
                      @successuploadcallback="successUploadCallback"
                    />
                  </div>
                  <p class="text-center text-sm text-secondary">
                    Silahkan lihat panduan upload file import.<br />
                    <a :href="downloadExampleURL" class="text-primary"
                      >Download disini</a
                    >
                  </p>
                  -or-
                  <p class="mt-3">
                    <button
                      class="btn btn-success"
                      @click.prevent="handleManualFormOrder"
                    >
                      Buat order manual</button
                    >&nbsp;
                    <button
                      class="btn btn-primary"
                      @click.prevent="viewOrderHistory"
                    >
                      View Order History
                    </button>
                  </p>
                </div>
              </div>
              <app-footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

  <modal v-model:show="modals.classic" size="lg">
    <div class="p-2">
      <BulkPackagesIO
        v-if="showBulkPackages"
        :slug="slug"
        :token="token"
        :timestamp="timestamp"
      />

      <hr style="margin-bottom: -20px" />
      <button
        type="button"
        class="btn btn-link"
        style="margin-top: 35px"
        @click.prevent="closeModalsClassic"
      >
        Close
      </button>
    </div>
  </modal>

  <modal v-model:show="modals.classic2" size="lg">
    <div class="p-2">
      <ManualFormOrderIO
        v-if="showManualFormOrder"
        :slug="slug"
        :token="token"
        :timestamp="timestamp"
        @successcreateordercallback="handleSuccessCreateOrderCallback"
      />

      <hr style="margin-bottom: -20px" />
      <button
        type="button"
        class="btn btn-link"
        style="margin-top: 35px"
        @click.prevent="closeModalsClassic2"
      >
        Close
      </button>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import AppFooter from "@/examples/Footer.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import Swal from "sweetalert2";
import GenUpload from "@/components/Generate/GenUpload.vue";
import BulkPackagesIO from "@/components/InstantOrder/BulkPackagesIO.vue";
import ManualFormOrderIO from "@/components/InstantOrder/ManualFormOrderIO.vue";
import Modal from "@/components/Modal";
import { ElLoading } from "element-plus";
import { mapMutations } from "vuex";
export default {
  name: "LockBasic",
  components: {
    AppFooter,
    ArgonButton,
    GenUpload,
    Modal,
    BulkPackagesIO,
    ManualFormOrderIO,
  },
  data() {
    return {
      modals: {
        classic: false,
        classic2: false,
      },
      urlread: "/api/v1/instant_input_orders/read/slug",
      urlunlock: "/api/v1/instant_input_orders/unlock",
      styleBg:
        "background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/lock-basic.jpg');",
      helperName: "",
      ownerName: "",
      managerName: "",
      merchantName: "",
      desc: "",
      descCreateOrder: "Please upload your CSV file to create order.",
      dataSlug: [],
      owner: [],
      merchant: [],
      isLoading: false,
      form: {
        slug: "",
        key: "",
        timestamp: "",
      },
      unlockForm: true,
      createOrderForm: false,
      token: "",
      timestamp: "",
      slug: "",
      uploadFormImport: false,

      showBulkPackages: false,
      showManualFormOrder: false,

      upload: {
        label: "",
        formatInfo: "",
        sizeInfo: "",
        type: "",
        accept: ".csv",
        pathUpload: "/api/v1/instant_input_orders/create/import",
        urlUpload: "",
        urlRead: "",
        pathFolder: "",
        display_file_after_upload: false,
        display_label: false,
      },

      downloadExampleURL: "",
      exampleFileName: "file-csv-import-example-inputorder.v1.0.0.csv",
    };
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    this.getDataSlug();
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),

    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },
    async getDataSlug() {
      try {
        this.isLoading = true;
        this.loadingService();
        let slug = this.$route.params.slug;
        this.form.slug = slug;
        this.slug = slug;
        const response = await axios.get(this.urlread + "/" + slug);
        if (response.data.status == "success") {
          // get timestamp
          this.form.timestamp = response.data.data.timestamp;
          // get dataSlug
          this.dataSlug = response.data.data;
          // get helper name
          this.helperName = response.data.data.helper_name;
          // get timestamp
          this.timestamp = response.data.data.timestamp;

          // get data manager
          if (response.data.data.manager != undefined) {
            this.managerName = response.data.data.manager.fullname;
            this.merchantName = response.data.data.manager.merchant;
          }

          this.desc = "Enter password to unlock your account.";
        } else {
          Swal.fire({
            title: "Warning!",
            html: "Data not found",
            icon: "warning",
            timer: 1500,
          });
        }

        this.loadingService().close();
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.isLoading = false;
      }
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        const response = await axios({
          method: "post",
          url: this.urlunlock,
          data: this.form,
        });
        //console.log(response.data);
        this.isLoading = false;
        if (response.data.status == "error") {
          Swal.fire({
            title: "Warning!",
            html: response.data.message,
            icon: "warning",
            timer: 1500,
          });
        } else if (response.data.status == "success") {
          this.unlockForm = false;
          this.createOrderForm = true;
          this.uploadFormImport = true;

          // get token
          this.token = response.data.token;
          this.upload.urlUpload =
            this.upload.pathUpload +
            "/" +
            this.token +
            "/" +
            this.timestamp +
            "/" +
            this.slug;
          //console.log(this.token);
          this.setDownloadExampleURL();
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    getUploadCallback(values) {
      this.uploadFormImport = values;
    },
    successUploadCallback(values) {
      if (values == true) {
        //this.$router.push({path: '/orders/bulkpackages'});
        Swal.fire({
          title: "Good Job!",
          html: "Successfully create order.",
          icon: "success",
          timer: 1500,
        });
      }
    },
    setDownloadExampleURL() {
      this.downloadExampleURL =
        axios.defaults.baseURL + "/uploads/import/" + this.exampleFileName;
    },
    viewOrderHistory() {
      this.modals.classic = true;
      this.showBulkPackages = true;
    },
    closeModalsClassic() {
      this.modals.classic = false;
      this.showBulkPackages = false;
    },
    handleManualFormOrder() {
      this.modals.classic2 = true;
      this.showManualFormOrder = true;
    },
    closeModalsClassic2() {
      this.modals.classic2 = false;
      this.showManualFormOrder = false;
    },
    handleSuccessCreateOrderCallback(values) {
      if (values == true) {
        this.modals.classic2 = false;
        this.showManualFormOrder = false;
      }
    },
  },
};
</script>
