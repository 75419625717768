<template>
  <argon-badge v-if="status == ''" variant="gradient" color="warning">
    Pending
  </argon-badge>
  <argon-badge v-if="status === null" variant="gradient" color="warning">
    Pending
  </argon-badge>
  <argon-badge
    v-else-if="status == 'Created'"
    variant="gradient"
    color="secondary"
  >
    {{ status }}
  </argon-badge>
  <argon-badge
    v-else-if="status == 'Cancelled'"
    variant="gradient"
    color="danger"
  >
    {{ status }}
  </argon-badge>
  <span
    v-else-if="status == 'Order Not Found'"
  >
    <small class="text-secondary"><i>{{ status }}</i></small>
  </span>
  <span v-else-if="status == 'Error'">
    <el-tooltip
      class="box-item"
      effect="dark"
      :content="message"
      placement="top-start"
    >
      <argon-badge variant="gradient" color="danger">
        {{ status }}
      </argon-badge>
    </el-tooltip>
  </span>
  
  <argon-badge
    v-else-if="status == 'Returned to Sender'"
    variant="gradient"
    color="danger"
  >
    {{ status }}
  </argon-badge>

  <argon-badge
    v-else-if="status == 'On Vehicle for Delivery (RTS)'"
    variant="gradient"
    color="danger"
  >
    {{ status }}
  </argon-badge>

  <argon-badge
    v-else-if="status == 'Return to Sender Triggered'"
    variant="gradient"
    color="danger"
  >
    {{ status }}
  </argon-badge>

  <argon-badge
    v-else-if="status == 'Successful Delivery'"
    variant="gradient"
    color="success"
  >
    {{ status }}
  </argon-badge>
  
  <argon-badge
    v-else-if="status == 'First Attempt Delivery Fail'"
    variant="gradient"
    color="danger"
  >
    {{ status }}
  </argon-badge>
  
  <argon-badge
    v-else-if="status == 'Completed'"
    variant="gradient"
    color="success"
  >
    {{ status }}
  </argon-badge>
  <argon-badge
    v-else-if="status == 'Pending Pickup'"
    variant="gradient"
    color="secondary"
  >
    {{ status }}
  </argon-badge>
  <argon-badge v-else variant="gradient" color="info">
    {{ status }}
  </argon-badge>
</template>

<script>
import ArgonBadge from "@/components/ArgonBadge.vue";
import { ElTooltip } from "element-plus";
export default {
  name: "TrackParcelStatus",
  components: {
    ArgonBadge,
    ElTooltip,
  },
  props: {
    status: { required: false, type: String },
    message: { required: false, type: String },
  },
};
</script>

<style></style>
