<template>
  <div v-if="loadingForm">
    <el-skeleton :rows="4" animated />
  </div>
  <div v-if="showForm">
    <h5 class="mb-0">Create Manual Order</h5>
    <p class="mb-0 text-sm">Form untuk membuat manual order pesanan.</p>
    <form @submit.prevent="onSubmit">
      <div class="row">
        <div class="col-md-6">
          <label class="form-label">Lokasi Dropship</label>
          <select
            v-model="form.official_dropship_store_code"
            class="form-select form-select-lg"
            @change="handleSelectProducts"
            required
          >
            <option
              v-for="select in selects.officialDropshipStore"
              :key="select.value"
              :value="select.value"
            >
              {{ select.name }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <label class="form-label">Nama Konsumen</label>
          <input
            v-model="form.to_name"
            type="text"
            class="form-control form-control-lg"
            required
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="form-label">Kecamatan</label>
          <AutocompleteZonecode
            v-if="showAutocompleteZonecode"
            id="origin"
            name="origin"
            name-i-d="ro_subdistrict_id"
            clasname="form-control form-control-lg"
            :urlsearch="urlSearchZonecodes"
            :value-i-d="form.ro_subdistrict_id"
            :value-area="form.area"
            get_district_id="yes"
            get_postalcode="yes"
            @autocompletecallback="handleAutocompleteCallback"
            @autocompleteloadingcallback="handleAutocompleteLoadingCallback"
          />
        </div>
        <div class="col-md-6">
          <label class="form-label">Kelurahan</label>
          <select v-model="form.subdistrict" class="form-select form-select-lg">
            <option
              v-for="select in selects.subdistricts"
              :key="select.name"
              :value="select.name"
            >
              {{ select.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <label class="form-label">Kodepos</label>
          <input
            v-model="form.postalcode"
            type="text"
            class="form-control form-control-lg"
            required
          />
          <label class="form-label">No. Handphone</label>
          <input
            v-model="form.to_phone_number"
            type="text"
            class="form-control form-control-lg"
            required
          />
        </div>
        <div class="col-md-8">
          <label class="form-label">Alamat Lengkap</label>
          <textarea
            v-model="form.to_address_1"
            class="form-control form-control-lg"
            style="height: 128px"
            required
          ></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <label class="form-label">Pilh Produk</label>
          <select
            v-model="form.product_name"
            class="form-select form-select-lg"
            required
          >
            <option
              v-for="select in selects.product_catalog"
              :key="select.product_name"
              :value="select.product_name"
            >
              {{ select.product_name }}
            </option>
          </select>
        </div>
        <div class="col-md-4">
          <label class="form-label">Jumlah (Qty)</label>
          <input
            v-model="form.qty"
            type="number"
            class="form-control form-control-lg"
            required
          />
        </div>
        <div class="col-md-4">
          <label class="form-label">Nilai COD</label>
          <input
            v-model="form.cash_on_delivery"
            type="text"
            class="form-control form-control-lg"
            required
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 pt-4">
          <h5 class="text-secondary"></h5>
        </div>
        <div class="col-md-6 text-end mt-4">
          <argon-button
            color="primary"
            variant="gradient"
            class="my-4 mb-2"
            :loading="isLoading"
            >Create Order</argon-button
          >
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import axios from "axios";
import ArgonButton from "@/components/ArgonButton.vue";
import { ElLoading, ElSkeleton } from "element-plus";
import Swal from "sweetalert2";
import AutocompleteZonecode from "@/components/AutocompleteZonecode.vue";
export default {
  name: "ManualFormOrderIO",
  components: {
    ArgonButton,
    ElSkeleton,
    AutocompleteZonecode,
  },
  props: {
    slug: { required: true, type: String },
    token: { required: true, type: String },
    timestamp: { required: true, type: String },
  },
  data() {
    return {
      urlSubmit: "/api/v1/instant_input_orders/manual_order/store",
      urlOfficialDropshipStore:
        "/api/v1/instant_input_orders/official_dropship_store/select",
      urlsearch: "/api/v1/instant_input_orders/search_zonecodes",
      urlSearchZonecodes: "",
      selects: {
        officialDropshipStore: [],
        subdistricts: [],
        product_catalog: [],
      },
      isLoading: false,
      loadingForm: false,
      showForm: false,

      showAutocompleteZonecode: true,

      form: {
        official_dropship_store_code: "",
        to_name: "",
        ro_subdistrict_id: "",
        subdistrict: "",
        area: "",
        postalcode: "",
        product_name: "",
        qty: "",
        cash_on_delivery: "",
      },
    };
  },
  created() {
    this.getOfficialDropshipStore();
    this.setURLSearchZonecodes();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },
    setURLSearchZonecodes() {
      // set urlRead
      let url = this.urlsearch;
      if (this.slug != "" && this.token != "" && this.timestamp != "") {
        url = url + "/" + this.token + "/" + this.timestamp + "/" + this.slug;
      }

      this.urlSearchZonecodes = url;
    },
    async getOfficialDropshipStore() {
      try {
        this.loadingForm = true;
        // set urlRead
        let url = this.urlOfficialDropshipStore;
        if (this.slug != "" && this.token != "" && this.timestamp != "") {
          url = url + "/" + this.token + "/" + this.timestamp + "/" + this.slug;
        }

        const response = await axios({
          method: "get",
          url: url,
        });
        this.selects.officialDropshipStore = response.data.data;
        this.loadingForm = false;
        this.showForm = true;
      } catch (error) {
        console.log(error);
        this.loadingForm = false;
        Swal.fire({
          title: "Warning!",
          html: error,
          icon: "warning",
        });
      }
    },
    async getSubDistrictSelect(district_id) {
      this.loadingService();
      try {
        const response = await axios({
          method: "get",
          url:
            "api/v1/instant_input_orders/subdistrict/select/" +
            district_id +
            "/" +
            this.token +
            "/" +
            this.timestamp +
            "/" +
            this.slug,
        });
        this.selects.subdistricts = response.data.data;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
      }
    },
    handleSelectSubDistrict() {
      this.getSubDistrictSelect(this.form.district_id);
    },
    async handleAutocompleteCallback(values) {
      if (values.district_id != undefined) {
        this.form.district_id = values.district_id;
        this.form.ro_subdistrict_id = values.id;
        this.form.area = values.area;
        this.handleSelectSubDistrict();
      }

      if (values.postalcodes[0].postalcode != undefined) {
        this.form.postalcode = values.postalcodes[0].postalcode;
      }
    },
    async handleAutocompleteLoadingCallback(values) {
      console.log(values);
    },
    async handleSelectProducts() {
      this.loadingService();
      try {
        const response = await axios({
          method: "get",
          url:
            "api/v1/instant_input_orders/product_catalog/select/" +
            this.form.official_dropship_store_code +
            "/" +
            this.token +
            "/" +
            this.timestamp +
            "/" +
            this.slug,
        });
        this.selects.product_catalog = response.data.data;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
      }
    },

    async onSubmit() {
      this.isLoading = true;
      try {
        // set urlRead
        let url = this.urlSubmit;
        if (this.slug != "" && this.token != "" && this.timestamp != "") {
          url = url + "/" + this.token + "/" + this.timestamp + "/" + this.slug;
        }

        const response = await axios({
          method: "post",
          url: url,
          data: this.form,
        });
        //console.log(response.data);
        this.isLoading = false;
        if (response.data.status == "error") {
          Swal.fire({
            title: "Warning!",
            html: response.data.message,
            icon: "warning",
            timer: 1500,
          });
        } else if (response.data.status == "success") {
          Swal.fire({
            title: "God Job!",
            html: 'Successfully create order',
            icon: "success",
            timer: 1500,
          });
          this.clearForm();
          // send callback
          this.$emit("successcreateordercallback", true);
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    clearForm() {
      for (let x in this.form) {
        this.form[x] = "";
      }
    },
  },
};
</script>
<style></style>
