<template>
  <div v-if="loadingTable">
    <el-skeleton :rows="4" animated />
  </div>
  <div v-if="showTableBulkPackages">
    <!-- Card header -->
    <h5 class="mb-0">Bulk Paket</h5>
    <p class="mb-0 text-sm">
      Daftar riwayat paket yang dibuat dari hasil file import (CSV).
    </p>

    <div class="mt-2">
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <el-select
          v-model="pagination.perPage"
          class="select-primary pagination-select"
          placeholder="Per page"
        >
          <el-option
            v-for="item in pagination.perPageOptions"
            :key="item"
            class="select-primary"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <div>
          <el-input
            v-model="searchQuery"
            type="search"
            class="mb-3"
            clearable
            prefix-icon="el-icon-search"
            style="width: 200px"
            placeholder="Search records"
            aria-controls="datatables"
          >
          </el-input>
        </div>
      </div>
      <div class="table-responsive">
        <el-table
          :data="queriedData"
          row-key="id"
          header-row-class-name="thead-light"
          @selection-change="selectionChange"
        >
          <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            v-bind="column"
          >
          </el-table-column>
          <el-table-column align="left" label="Kode Bulk">
            <template #default="props">
              <a href="#" @click.prevent="showDetail(props.$index, props.row)"
                ><b>{{ props.row.code }}</b
                ><br />
                <i class="text-sm text-secondary">{{
                  props.row.display_created_at
                }}</i></a
              >
            </template>
          </el-table-column>
          <el-table-column align="left" label="Progress">
            <template #default="props">
              <div class="text-success">
                {{ props.row.total_success }}/{{ props.row.total }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="left" label="Status">
            <template #default="props">
              <span
                v-if="props.row.status == 'completed'"
                class="badge badge-pill badge-success"
                >{{ props.row.status }}</span
              >
              <span
                v-else-if="props.row.status == 'incompleted'"
                class="badge badge-pill badge-warning"
                >{{ props.row.status }}</span
              >
              <span
                v-else-if="props.row.status == 'created'"
                class="badge badge-pill badge-secondary"
                >{{ props.row.status }}</span
              >
            </template>
          </el-table-column>
          <el-table-column align="left" label="Helper">
            <template #default="props">
              <small class="text-secondary">{{ props.row.helper_name }}</small>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <br />
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div class="">
          <p class="card-category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          </p>
        </div>
        <base-pagination
          v-model="pagination.currentPage"
          class="pagination-no-border"
          :per-page="pagination.perPage"
          :total="total"
        >
        </base-pagination>
      </div>
    </div>
  </div>

  <div v-if="showTableBulkListPackages">
    <div class="row">
        <div class="col-md-6">
            <span v-html="selectedBulkPackagesLabel"></span>
        </div>
        <div class="col-md-6">
            <div class="text-end"><button class="btn btn-warning btn-sm" @click.prevent="handleShowTableBulkPackages">Go Back</button></div>
        </div>
    </div>
    <BulkListPackagesIO :slug="slug" :token="token" :timestamp="timestamp" :bulk_package_id="selectedBulkPackageID" />
  </div>
</template>
<script>
import axios from "axios";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
  ElLoading,
  ElSkeleton,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import Swal from "sweetalert2";
import BulkListPackagesIO from "@/components/InstantOrder/BulkListPackagesIO.vue";

export default {
  name: "BulkPackagesIO",
  components: {
    ElSkeleton,
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    BulkListPackagesIO,
  },
  props: {
    slug: { required: true, type: String },
    token: { required: true, type: String },
    timestamp: { required: true, type: String },
  },
  data() {
    return {
      modals: {
        classic: false,
        classic2: false,
      },
      urlread: "/api/v1/instant_input_orders/order_history/bulk_packages",
      dataTable: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["code", "status", "display_created_at"],
      tableColumns: [
        {
          type: "selection",
        },
      ],
      datas: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],

      loadingTable: false,
      showTable: false,
      showTableBulkPackages: false,
      showTableBulkListPackages: false,
      selectedBulkPackageID: 0,
      bulkPackages: [],
      selectedBulkPackagesLabel: "",
    };
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  created() {
    this.getTables();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },
    async getTables() {
      try {
        this.loadingTable = true;
        // set urlRead
        let url = this.urlread;
        if (this.slug != "" && this.token != "" && this.timestamp != "") {
          url = url + "/" + this.token + "/" + this.timestamp + "/" + this.slug;
        }

        const response = await axios({
          method: "get",
          url: url,
        });

        console.log(response.data);
        if (response.data.status == "success") {
          this.bulkPackages = response.data.data;
          this.datas = response.data.data;
          this.tableData = this.datas;
        } else if (response.data.status == "error") {
          Swal.fire({
            title: "Warning!",
            html: response.data.message,
            icon: "warning",
            timer: 1500,
          });
        }
        this.loadingTable = false;
        this.showTableBulkPackages = true;
      } catch (error) {
        console.log(error);
        this.loadingTable = false;
        Swal.fire({
          title: "Warning!",
          html: error,
          icon: "warning",
        });
      }
    },
    showDetail(index, row) {
        this.selectedBulkPackageID = row.id;
        this.showTableBulkPackages = false;
        this.showTableBulkListPackages = true;
        this.selectedBulkPackagesLabel = '<div>Kode Bulk: <b>'+row.code+'</b><div><div class="text-sm text-secondary" style="margin-top:-5px;"><small><i>'+row.display_created_at+'</i></small></div>';
    },
    handleShowTableBulkPackages() {
        this.showTableBulkPackages = true;
        this.showTableBulkListPackages = false;
    }
  },
};
</script>
<style></style>
