<template>
  <div class="autocomplete">
    <input :id="nameID" v-model="searchID" type="hidden" :name="nameID" />
    <input
      v-model="search"
      v-debounce:500ms="filterSearch"
      type="text"
      class="form-control form-control-lg"
      :urlsearch="urlsearch"
      :disabled="disabledForm == 1"
      autocomplete="off"
      @blur="blurSearch"
    />
    <div v-if="showResults" :class="classResults">
      <div v-if="isLoading" class="loading">loading..</div>
      <div v-if="isNotFound" class="notfound">not found</div>
      <div v-if="showResultLists" class="resultlists">
        <ul v-for="zonecode in zonecodes" :key="zonecode.id">
          <li>
            <a href="#" @click="selectedItem(zonecode)">{{ zonecode.area }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { vue3Debounce } from "vue-debounce";

export default {
  name: "AutocompleteZonecode",
  props: {
    name: { required: false, type: String },
    nameID: { required: false, type: String },
    documentID: { required: false, type: String },
    urlsearch: { required: false, type: String },
    valueID: { required: false, type: String },
    valueArea: { required: false, type: String },
    get_district_id: { required: false, default: 'no', type: String },
    get_postalcode: { required: false, default: 'no', type: String },
    disabledForm: { required: false, type: Boolean },
  },
  directives: {
    debounce: vue3Debounce({ lock: true }),
  },
  data() {
    return {
      isLoading: false,
      isNotFound: false,
      search: "",
      searchID: "",
      showResults: false,
      showResultLists: false,
      zonecodes: [],
      classResults: "results",
      autocompletecallback: [],
      autocompleteloadingcallback: false,
    };
  },
  created() {
    this.search = this.valueArea;
    this.searchID = this.valueID;
  },
  methods: {
    async filterSearch() {
      this.searchID = "";
      if (this.search.length >= 3) {
        this.autocompleteLoadingCallback = true;
        this.$emit("autocompleteloadingcallback", true);
        this.isNotFound = false;
        this.showResults = true;
        this.isLoading = true;
        this.showResultLists = false;

        this.APIFilterSearch();
      } else {
        this.zonecodes = [];
        this.showResults = false;
        this.isLoading = false;
      }
    },
    async APIFilterSearch() {
      try {
        const response = await axios({
          method: "post",
          url: this.urlsearch,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            search: this.search,
            get_district_id: this.get_district_id,
            get_postalcode: this.get_postalcode,
          },
        });
        this.zonecodes = response.data.zonecodes;
        this.isLoading = false;
        this.showResultLists = true;
        if (response.data.total == 0) {
          this.isNotFound = true;
          this.classResults = "results";
          // set timeout
          setTimeout(() => {
            if (this.zonecodes.length == 0) {
              this.showResults = false;
            }
          }, 3000);
        } else {
          if (response.data.total > 7) {
            this.classResults = "results results-extended";
          } else {
            this.classResults = "results";
          }
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.errorRedirect500(error);
      }
    },
    filterOut() {
      this.showResults = false;
      this.isLoading = false;
      this.showResultLists = false;
    },
    blurSearch() {
      if (this.searchID == "") {
        this.search = "";
      }
    },
    selectedItem(zonecode) {
      this.searchID = zonecode.id;
      this.search = zonecode.area;
      this.filterOut();

      this.$emit("autocompletecallback", zonecode);
    },
    errorRedirect500(error) {
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
  },
};
</script>

<style scoped>
.autocomplete {
  position: relative;
}
.autocomplete .results {
  position: absolute;
  width: 300px;
  margin-top: 5px;
  border-radius: 5px 5px 5px 5px;
  z-index: 1;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  padding: 10px;
}
.autocomplete .results-extended {
  height: 300px;
  overflow-y: scroll;
}
.autocomplete .results .loading {
  font-size: 14px;
  color: #cccccc;
}
.autocomplete .results .notfound {
  font-size: 14px;
  color: #cccccc;
}
.autocomplete .resultlists {
  margin: 0px;
  padding: 0px;
}
.autocomplete .results ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
.autocomplete .results ul li {
  border-bottom: 1px solid #eeeeee;
  border-radius: 5px;
}
.autocomplete .results ul li:last-child {
  border-bottom: 1px solid #ffffff;
}
.autocomplete .results ul li:hover {
  background-color: #6c6be4;
}
.autocomplete .results ul a {
  display: block;
  font-size: 14px;
  color: #8c96aa;
  padding: 10px;
}
.autocomplete .results ul a:hover {
  color: #ffffff;
}
</style>