import { createRouter, createWebHistory } from "vue-router";
//import Landing from "../views/dashboards/Landing.vue";
const Landing = () => import('../views/dashboards/Landing.vue')
//import Default from "../views/dashboards/Default.vue";
const Default = () => import('../views/dashboards/Default.vue')
//import Dashboard from "../views/dashboards/Dashboard.vue";
const Dashboard = () => import('../views/dashboards/Dashboard.vue')
//import Statistics from "../views/dashboards/Statistics.vue";
const Statistics = () => import('../views/dashboards/Statistics.vue')

//import Automotive from "../views/dashboards/Automotive.vue";
const Automotive = () => import('../views/dashboards/Automotive.vue')
//import SmartHome from "../views/dashboards/SmartHome.vue";
const SmartHome = () => import('../views/dashboards/SmartHome.vue')
//import VRDefault from "../views/dashboards/vr/VRDefault.vue";
const VRDefault = () => import('../views/dashboards/vr/VRDefault.vue')
//import VRInfo from "../views/dashboards/vr/VRInfo.vue";
const VRInfo = () => import('../views/dashboards/vr/VRInfo.vue')
//import CRM from "../views/dashboards/CRM.vue";
const CRM = () => import('../views/dashboards/CRM.vue')

//import BranchOffices from "../views/pages/masterdata/BranchOffices/BranchOffices.vue";
const BranchOffices = () => import('../views/pages/masterdata/BranchOffices/BranchOffices.vue')
//import Departments from "../views/pages/masterdata/Departments/Departments.vue";
const Departments = () => import('../views/pages/masterdata/Departments/Departments.vue')
//import Jobpositions from "../views/pages/masterdata/Jobpositions/Jobpositions.vue";
const Jobpositions = () => import('../views/pages/masterdata/Jobpositions/Jobpositions.vue')
//import Users from "../views/pages/Users/Users.vue";
const Users = () => import('../views/pages/Users/Users.vue')
//import CourierAgents from "../views/pages/CourierAgents.vue";
const CourierAgents = () => import('../views/pages/CourierAgents.vue')
//import OfficialDropshipStore from "../views/pages/Dropship/OfficialDropshipStore.vue";
const OfficialDropshipStore = () => import('../views/pages/Dropship/OfficialDropshipStore.vue')
//import OfficialProducts from "../views/pages/Dropship/OfficialProducts.vue";
const OfficialProducts = () => import('../views/pages/Dropship/OfficialProducts.vue')
//import CashbackPrograms from "../views/pages/CashbackPrograms.vue";
const CashbackPrograms = () => import('../views/pages/CashbackPrograms.vue')
//import CashbackProgramUsers from "../views/pages/CashbackProgramUsers.vue";
const CashbackProgramUsers = () => import('../views/pages/CashbackProgramUsers.vue')
//import SendPackagesFromAdmin from "../views/pages/SendPackagesFromAdmin.vue";
const SendPackagesFromAdmin = () => import('../views/pages/SendPackagesFromAdmin.vue')

//import AttemptDeliveryFailFromAdmin from "../views/pages/AlertSendPackagesStatus/AttemptDeliveryFailFromAdmin.vue";
const AttemptDeliveryFailFromAdmin = () => import('../views/pages/AlertSendPackagesStatus/AttemptDeliveryFailFromAdmin.vue')

//import Overview from "../views/pages/profile/Overview.vue";
const Overview = () => import('../views/pages/profile/Overview.vue')
//import Teams from "../views/pages/profile/Teams.vue";
const Teams = () => import('../views/pages/profile/Teams.vue')
//import Projects from "../views/pages/profile/Projects.vue";
const Projects = () => import('../views/pages/profile/Projects.vue')
//import General from "../views/pages/projects/General.vue";
const General = () => import('../views/pages/projects/General.vue')
//import Timeline from "../views/pages/projects/Timeline.vue";
const Timeline = () => import('../views/pages/projects/Timeline.vue')
//import NewProject from "../views/pages/projects/NewProject.vue";
const NewProject = () => import('../views/pages/projects/NewProject.vue')
//import Pricing from "../views/pages/Pricing.vue";
const Pricing = () => import('../views/pages/Pricing.vue')
//import RTL from "../views/pages/Rtl.vue";
const RTL = () => import('../views/pages/Rtl.vue')
//import Charts from "../views/pages/Charts.vue";
const Charts = () => import('../views/pages/Charts.vue')
//import SweetAlerts from "../views/pages/SweetAlerts.vue";
const SweetAlerts = () => import('../views/pages/SweetAlerts.vue')
//import Notifications from "../views/pages/Notifications.vue";
const Notifications = () => import('../views/pages/Notifications.vue')

//import TariffChecking from "../views/applications/TariffChecking.vue";
const TariffChecking = () => import('../views/applications/TariffChecking.vue')
//import SendPackages from "../views/applications/SendPackages.vue";
const SendPackages = () => import('../views/applications/SendPackages.vue')
//import CreateSendPackages from "../views/applications/CreateSendPackages.vue";
const CreateSendPackages = () => import('../views/applications/CreateSendPackages.vue')
const ImportSendPackages = () => import('../views/applications/ImportSendPackages.vue')
//import HistoryPackages from "../views/applications/HistoryPackages.vue";
const HistoryPackages = () => import('../views/applications/HistoryPackages.vue')
const BulkPackages = () => import('../views/applications/BulkPackages.vue')
const BulkPackagesFromAdmin = () => import('../views/applications/BulkPackagesFromAdmin.vue')
//import AddressLists from "../views/applications/AddressLists.vue";
const AddressLists = () => import('../views/applications/AddressLists.vue')

// finance
const Balance = () => import('../views/finance/Balance.vue')
const BalanceFromAdmin = () => import('../views/finance/BalanceFromAdmin.vue')

//import Kanban from "../views/applications/Kanban.vue";
const Kanban = () => import('../views/applications/Kanban.vue')
//import Wizard from "../views/applications/wizard/Wizard.vue";
const Wizard = () => import('../views/applications/wizard/Wizard.vue')
//import DataTables from "../views/applications/DataTables.vue";
const DataTables = () => import('../views/applications/DataTables.vue')
//import Calendar from "../views/applications/Calendar.vue";
const Calendar = () => import('../views/applications/Calendar.vue')
//import Analytics from "../views/applications/analytics/Analytics.vue";
const Analytics = () => import('../views/applications/analytics/Analytics.vue')
//import EcommerceOverview from "../views/ecommerce/overview/Overview.vue";
const EcommerceOverview = () => import('../views/ecommerce/overview/Overview.vue')
//import NewProduct from "../views/ecommerce/products/NewProduct.vue";
const NewProduct = () => import('../views/ecommerce/products/NewProduct.vue')
//import EditProduct from "../views/ecommerce/EditProduct.vue";
const EditProduct = () => import('../views/ecommerce/EditProduct.vue')
//import ProductPage from "../views/ecommerce/ProductPage.vue";
const ProductPage = () => import('../views/ecommerce/ProductPage.vue')
//import ProductsList from "../views/ecommerce/ProductsList.vue";
const ProductsList = () => import('../views/ecommerce/ProductsList.vue')
//import OrderDetails from "../views/ecommerce/Orders/OrderDetails";
const OrderDetails = () => import('../views/ecommerce/Orders/OrderDetails')
//import OrderList from "../views/ecommerce/Orders/OrderList";
const OrderList = () => import('../views/ecommerce/Orders/OrderList')
//import Referral from "../views/ecommerce/Referral";
const Referral = () => import('../views/ecommerce/Referral')
//import Reports from "../views/pages/Users/Reports.vue";
const Reports = () => import('../views/pages/Users/Reports.vue')
//import NewUser from "../views/pages/Users/NewUser.vue";
const NewUser = () => import('../views/pages/Users/NewUser.vue')
//import Settings from "../views/pages/Account/Settings.vue";
const Settings = () => import('../views/pages/Account/Settings.vue')
const InstantInputOrders = () => import('../views/pages/Account/InstantInputOrders.vue')
//import Billing from "../views/pages/Account/Billing.vue";
const Billing = () => import('../views/pages/Account/Billing.vue')
//import Invoice from "../views/pages/Account/Invoice.vue";
const Invoice = () => import('../views/pages/Account/Invoice.vue')
//import Security from "../views/pages/Account/Security.vue";
const Security = () => import('../views/pages/Account/Security.vue')
//import Widgets from "../views/pages/Widgets.vue";
const Widgets = () => import('../views/pages/Widgets.vue')

import Login from "../views/auth/signin/Login.vue";
//const Login = () => import('../views/auth/signin/Login.vue')

import GoogleAuth from "../views/auth/signin/GoogleAuth.vue";
//const GoogleAuth = () => import('../views/auth/signin/GoogleAuth.vue')

//import Logout from "../views/auth/logout/Logout.vue";
const Logout = () => import('../views/auth/logout/Logout.vue')
//import Basic from "../views/auth/signin/Basic.vue";
const Basic = () => import('../views/auth/signin/Basic.vue')
//import Cover from "../views/auth/signin/Cover.vue";
const Cover = () => import('../views/auth/signin/Cover.vue')
//import Illustration from "../views/auth/signin/Illustration.vue";
const Illustration = () => import('../views/auth/signin/Illustration.vue')
//import ResetBasic from "../views/auth/reset/Basic.vue";
const ResetBasic = () => import('../views/auth/reset/Basic.vue')
//const ResetPassword = () => import('../views/auth/reset/ResetPassword.vue')
import ResetPassword from "../views/auth/reset/ResetPassword.vue";
import ResetPasswordVerify from "../views/auth/reset/ResetPasswordVerify.vue";
//import ResetCover from "../views/auth/reset/Cover.vue";
const ResetCover = () => import('../views/auth/reset/Cover.vue')
//import ResetIllustration from "../views/auth/reset/Illustration.vue";
const ResetIllustration = () => import('../views/auth/reset/Illustration.vue')
//import VerificationBasic from "../views/auth/verification/Basic.vue";
const VerificationBasic = () => import('../views/auth/verification/Basic.vue')
//import VerificationCover from "../views/auth/verification/Cover.vue";
const VerificationCover = () => import('../views/auth/verification/Cover.vue')
//import VerificationIllustration from "../views/auth/verification/Illustration.vue";
const VerificationIllustration = () => import('../views/auth/verification/Illustration.vue')
//import SignupBasic from "../views/auth/signup/Basic.vue";
const SignupBasic = () => import('../views/auth/signup/Basic.vue')
//import SignupCover from "../views/auth/signup/Cover.vue";
const SignupCover = () => import('../views/auth/signup/Cover.vue')
import SignupIllustration from "../views/auth/signup/Illustration.vue";
import RegisterForMember from "../views/auth/signup/RegisterForMember.vue";
//const SignupIllustration = () => import('../views/auth/signup/Illustration.vue')
//import Error404 from "../views/auth/error/Error404.vue";
const Error404 = () => import('../views/auth/error/Error404.vue')
//import Error500 from "../views/auth/error/Error500.vue";
const Error500 = () => import('../views/auth/error/Error500.vue')
//import lockBasic from "../views/auth/lock/Basic.vue";
const lockBasic = () => import('../views/auth/lock/Basic.vue')
//import lockCover from "../views/auth/lock/Cover.vue";
const lockCover = () => import('../views/auth/lock/Cover.vue')
//import lockIllustration from "../views/auth/lock/Illustration.vue";
const lockIllustration = () => import('../views/auth/lock/Illustration.vue')

import AuthInstantOrder from "../views/auth/instant_order/AuthInstantOrder.vue";
//const AuthInstantOrder = () => import('../views/auth/instant_order/AuthInstantOrder.vue')

const routes = [
  { path: '/:pathMatch(.*)*', 
    name: 'Error404', 
    component: Error404 
  },
  {
    path: "/",
    name: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/googleauth",
    name: "GoogleAuth",
    component: GoogleAuth
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout
  },
  {
    path: "/instant/order/:slug",
    name: "AuthInstantOrder",
    component: AuthInstantOrder
  },
  {
    path: "/dashboards",
    name: "Dashboard",
    component: Dashboard
  },
  {
    path: "/pages/statistics",
    name: "Statistics",
    component: Statistics
  },
  {
    path: "/dashboards/dashboard-default",
    name: "Default",
    component: Default
  },
  {
    path: "/dashboards/landing",
    name: "Landing",
    component: Landing
  },
  {
    path: "/dashboards/automotive",
    name: "Automotive",
    component: Automotive
  },
  {
    path: "/dashboards/smart-home",
    name: "Smart Home",
    component: SmartHome
  },
  {
    path: "/dashboards/vr/vr-default",
    name: "VR Default",
    component: VRDefault
  },
  {
    path: "/dashboards/vr/vr-info",
    name: "VR Info",
    component: VRInfo
  },
  {
    path: "/dashboards/crm",
    name: "CRM",
    component: CRM
  },
  {
    path: "/pages/masterdata/branchoffices",
    name: "BranchOffices",
    component: BranchOffices
  },
  {
    path: "/pages/masterdata/departments",
    name: "Departments",
    component: Departments
  },
  {
    path: "/pages/masterdata/jobpositions",
    name: "Jobpositions",
    component: Jobpositions
  },
  {
    path: "/pages/profile/overview",
    name: "Profile Overview",
    component: Overview
  },
  {
    path: "/pages/profile/teams",
    name: "Teams",
    component: Teams
  },
  {
    path: "/pages/profile/projects",
    name: "All Projects",
    component: Projects
  },
  {
    path: "/pages/projects/general",
    name: "General",
    component: General
  },
  {
    path: "/pages/projects/timeline",
    name: "Timeline",
    component: Timeline
  },
  {
    path: "/pages/projects/new-project",
    name: "New Project",
    component: NewProject
  },
  {
    path: "/pages/pricing-page",
    name: "Pricing Page",
    component: Pricing
  },
  {
    path: "/pages/rtl-page",
    name: "RTL",
    component: RTL
  },
  {
    path: "/pages/charts",
    name: "Charts",
    component: Charts
  },
  {
    path: "/pages/widgets",
    name: "Widgets",
    component: Widgets
  },
  {
    path: "/pages/sweet-alerts",
    name: "Sweet Alerts",
    component: SweetAlerts
  },
  {
    path: "/pages/notifications",
    name: "Notifications",
    component: Notifications
  },
  {
    path: "/orders/tariffchecking",
    name: "TariffChecking",
    component: TariffChecking
  },
  {
    path: "/orders/sendpackages/create",
    name: "CreateSendPackages",
    component: CreateSendPackages
  },
  {
    path: "/orders/sendpackages/import",
    name: "ImportSendPackages",
    component: ImportSendPackages
  },
  {
    path: "/orders/historypackages",
    name: "HistoryPackages",
    component: HistoryPackages
  },
  {
    path: "/orders/bulkpackages",
    name: "BulkPackages",
    component: BulkPackages
  },
  {
    path: "/orders/addresslists",
    name: "AddressLists",
    component: AddressLists
  },
  {
    path: "/applications/kanban",
    name: "Kanban",
    component: Kanban
  },
  {
    path: "/applications/wizard",
    name: "Wizard",
    component: Wizard
  },
  {
    path: "/applications/data-tables",
    name: "Data Tables",
    component: DataTables
  },
  {
    path: "/applications/calendar",
    name: "Calendar",
    component: Calendar
  },
  {
    path: "/applications/analytics",
    name: "Analytics",
    component: Analytics
  },
  {
    path: "/finance/balance",
    name: "Balance",
    component: Balance
  },
  {
    path: "/ecommerce/overview",
    name: "Overview",
    component: EcommerceOverview
  },
  {
    path: "/ecommerce/products/new-product",
    name: "New Product",
    component: NewProduct
  },
  {
    path: "/ecommerce/products/edit-product",
    name: "Edit Product",
    component: EditProduct
  },
  {
    path: "/ecommerce/products/product-page",
    name: "Product Page",
    component: ProductPage
  },
  {
    path: "/ecommerce/products/products-list",
    name: "Products List",
    component: ProductsList
  },
  {
    path: "/ecommerce/Orders/order-details",
    name: "Order Details",
    component: OrderDetails
  },
  {
    path: "/ecommerce/Orders/order-list",
    name: "Order List",
    component: OrderList
  },
  {
    path: "/ecommerce/referral",
    name: "Referral",
    component: Referral
  },
  {
    path: "/pages/courieragents",
    name: "CourierAgents",
    component: CourierAgents
  },
  {
    path: "/pages/dropship/officialdropshipstore",
    name: "OfficialDropshipStore",
    component: OfficialDropshipStore
  },
  {
    path: "/pages/dropship/officialproducts",
    name: "OfficialProducts",
    component: OfficialProducts
  },
  {
    path: "/pages/cashbackprograms",
    name: "CashbackPrograms",
    component: CashbackPrograms
  },
  {
    path: "/pages/cashbackprogramusers",
    name: "CashbackProgramUsers",
    component: CashbackProgramUsers
  },
  {
    path: "/pages/admin/send_packages",
    name: "SendPackagesFromAdmin",
    component: SendPackagesFromAdmin
  },
  {
    path: "/pages/admin/bulk_packages",
    name: "BulkPackagesFromAdmin",
    component: BulkPackagesFromAdmin
  },
  {
    path: "/pages/admin/attempt_delivery_fail",
    name: "AttemptDeliveryFailFromAdmin",
    component: AttemptDeliveryFailFromAdmin
  },
  {
    path: "/pages/admin/balance",
    name: "BalanceFromAdmin",
    component: BalanceFromAdmin
  },
  {
    path: "/orders/send_packages",
    name: "SendPackages",
    component: SendPackages
  },
  {
    path: "/pages/users",
    name: "Users",
    component: Users
  },
  {
    path: "/pages/users/reports",
    name: "Reports",
    component: Reports
  },
  {
    path: "/pages/users/new-user",
    name: "New User",
    component: NewUser
  },
  {
    path: "/account/settings",
    name: "Settings",
    component: Settings
  },
  {
    path: "/account/instant_input_orders",
    name: "InstantInputOrders",
    component: InstantInputOrders
  },
  {
    path: "/account/billing",
    name: "Billing",
    component: Billing
  },
  {
    path: "/pages/account/invoice",
    name: "Invoice",
    component: Invoice
  },
  {
    path: "/account/Security",
    name: "Security",
    component: Security
  },
  {
    path: "/authentication/signin/basic",
    name: "Signin Basic",
    component: Basic
  },
  {
    path: "/authentication/signin/cover",
    name: "Signin Cover",
    component: Cover
  },
  {
    path: "/authentication/signin/illustration",
    name: "Signin Illustration",
    component: Illustration
  },
  {
    path: "/authentication/reset/password",
    name: "Reset Password",
    component: ResetPassword
  },
  {
    path: "/authentication/reset/password/verify/:linkcode",
    name: "Reset Password Verify",
    component: ResetPasswordVerify
  },
  {
    path: "/authentication/reset/basic",
    name: "Reset Basic",
    component: ResetBasic
  },
  {
    path: "/authentication/reset/cover",
    name: "Reset Cover",
    component: ResetCover
  },
  {
    path: "/authentication/reset/illustration",
    name: "Reset Illustration",
    component: ResetIllustration
  },
  {
    path: "/authentication/lock/basic",
    name: "Lock Basic",
    component: lockBasic
  },
  {
    path: "/authentication/lock/cover",
    name: "Lock Cover",
    component: lockCover
  },
  {
    path: "/authentication/lock/illustration",
    name: "Lock Illustration",
    component: lockIllustration
  },
  {
    path: "/authentication/verification/basic",
    name: "Verification Basic",
    component: VerificationBasic
  },
  {
    path: "/authentication/verification/cover",
    name: "Verification Cover",
    component: VerificationCover
  },
  {
    path: "/authentication/verification/illustration",
    name: "Verification Illustration",
    component: VerificationIllustration
  },
  {
    path: "/authentication/signup/basic",
    name: "Signup Basic",
    component: SignupBasic
  },
  {
    path: "/authentication/signup/cover",
    name: "Signup Cover",
    component: SignupCover
  },
  {
    path: "/authentication/signup/illustration",
    name: "Signup Illustration",
    component: SignupIllustration
  },
  {
    path: "/authentication/signup/register_for_member",
    name: "Register For Member",
    component: RegisterForMember
  },
  
  {
    path: "/authentication/error/error404",
    name: "Error Error404",
    component: Error404
  },
  {
    path: "/authentication/error/error500",
    name: "Error Error500",
    component: Error500
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active"
});

export default router;