/* eslint-disable prettier/prettier */
<template>
  <span v-if="disabled == 'disabled'">
    <button
      disabled
      class="btn mb-0"
      :class="getClasses(variant, color, size, fullWidth, active, loading)"
    >
      <slot name="loading">
        <i v-if="loading" class="fas fa-spinner fa-spin"></i>&nbsp;
      </slot>
      <slot />
    </button>
  </span>
  <span v-else>
    <button
      class="btn mb-0"
      :class="getClasses(variant, color, size, fullWidth, active, loading)"
    >
      <slot name="loading">
        <i v-if="loading" class="fas fa-spinner fa-spin"></i>&nbsp;
      </slot>
      <slot />
    </button>
  </span>
</template>

<script>
export default {
  name: "ArgonButton",
  props: {
    color: {
      type: String,
      default: "success",
    },
    size: {
      type: String,
      default: "md",
    },
    variant: {
      type: String,
      default: "fill",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: String,
      default: "",
    },
  },
  methods: {
    getClasses: (
      variant,
      color,
      size,
      fullWidth,
      active,
      disabled,
      loading
    ) => {
      let colorValue,
        sizeValue,
        fullWidthValue,
        activeValue,
        disabledValue,
        loadingValue;

      // Setting the button variant and color
      if (variant === "gradient") {
        colorValue = `bg-gradient-${color}`;
      } else if (variant === "outline") {
        colorValue = `btn-outline-${color}`;
      } else {
        colorValue = `btn-${color}`;
      }

      sizeValue = size ? `btn-${size}` : null;

      fullWidthValue = fullWidth ? `w-100` : null;

      activeValue = active ? `active` : null;

      disabledValue = disabled ? `disabled` : null;

      loadingValue = loading ? `loading` : null;

      if (loading == true) {
        disabledValue = "disabled";
      } else {
        disabledValue = null;
      }

      return `${colorValue} ${sizeValue} ${fullWidthValue} ${activeValue} ${disabledValue} ${loadingValue}`;
    },
  },
};
</script>
