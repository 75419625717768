<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          v-show="false"
          is-blur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>

  <main class="main-content main-content-bg mt-0">
    <div
      class="page-header min-vh-100"
      style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/reset-basic.jpg');
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7">
            <div class="card z-index-0 mb-7">
              <div class="card-header text-center pt-4 pb-1">
                <h4 class="font-weight-bolder mb-1">Insert PIN</h4>
                <p class="mb-0">and you can recovery your password.</p>
              </div>
              <div class="card-body text-center">
                <argon-alert
                  v-if="showErrorMessage"
                  color="danger"
                  style="font-size: 12px"
                  >{{ errorMessage }}</argon-alert
                >
                <argon-alert
                  v-if="showSuccessMessage"
                  color="success"
                  style="font-size: 12px"
                  >{{ successMessage }}</argon-alert
                >
                <form
                  v-if="showForm"
                  role="form"
                  @submit.prevent="handleSubmit"
                >
                  <argon-input
                    id="code"
                    type="text"
                    name="code"
                    placeholder="Insert Pin Code"
                    aria-label="Pin Code"
                    :is-required="isRequired"
                  />
                  <argon-input
                    id="newpassword"
                    type="password"
                    name="newpassword"
                    placeholder="New Password"
                    aria-label="New Password"
                    :is-required="isRequired"
                  />
                  <argon-input
                    id="retypenewpassword"
                    type="password"
                    name="retypenewpassword"
                    placeholder="Retype New Password"
                    aria-label="Retype New Password"
                    :is-required="isRequired"
                  />
                  <div class="text-center">
                    <argon-button
                      color="primary"
                      variant="gradient"
                      class="my-4 mb-2"
                      :loading="isLoading"
                      full-width
                      >Change Password</argon-button
                    >
                  </div>
                </form>
                <div
                  v-if="separateRule"
                  class="mb-2 mt-4 position-relative text-center"
                >
                  <p
                    class="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3"
                  >
                    or
                  </p>
                </div>
                <div class="text-center mt-4">
                  <argon-button
                    color="dark"
                    variant="outline"
                    :loading="isLoadingBackToLogin"
                    @click.prevent="handleToLogin"
                    >Back to Login</argon-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer v-show="false" />
</template>

<script>
import axios from "axios";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonAlert from "@/components/ArgonAlert.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import { ElLoading } from "element-plus";

export default {
  name: "ResetPassword",
  components: {
    Navbar,
    AppFooter,
    ArgonInput,
    ArgonButton,
    ArgonAlert,
  },
  data() {
    return {
      urlVerifyLinkAccess: "/api/v1/password_reset/verify_link_access",
      urlPasswordReset: "/api/v1/password_reset/change",
      linkcode: "",
      token: "",
      isLoading: false,
      isLoadingBackToLogin: false,
      isRequired: true,
      errorMessage: "",
      successMessage: "",
      showErrorMessage: false,
      showSuccessMessage: false,
      showForm: false,
      separateRule: true,
    };
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    body.classList.remove("bg-gray-200");
    this.getHmacURL();
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
    body.classList.add("bg-gray-200");
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },
    getHmacURL() {
      if (this.$route.params.linkcode !== undefined) {
        this.linkcode = this.$route.params.linkcode;

        this.VerifyLinkAccess(this.linkcode);
      }
    },
    async VerifyLinkAccess(linkcode) {
      try {
        this.loadingService();
        const response = await axios({
          method: "get",
          url: this.urlVerifyLinkAccess + "/" + linkcode,
        });
        this.isLoading = false;
        if (response.data.status == "success") {
          this.token = response.data.token;
          this.showForm = true;
        } else {
          this.errorMessage = response.data.message;
          this.showErrorMessage = true;
        }
        this.loadingService().close();
      } catch (error) {
        this.loadingService().close();
        this.isLoading = false;
        console.log(error);
        this.errorMessage = "ERROR! Something went wrong!";
        this.showErrorMessage = true;
      }
    },
    async handleSubmit(values) {
      try {
        this.showErrorMessage = false;
        this.isLoading = true;

        const response = await axios({
          method: "post",
          url: this.urlPasswordReset,
          data: {
            code: values.target["code"].value,
            new_password: values.target["newpassword"].value,
            retype_new_password: values.target["retypenewpassword"].value,
            token: this.token,
          },
        });
        this.isLoading = false;
        console.log(response.data);
        if (response.data.status == "success") {
          this.successMessage = response.data.message;
          this.showSuccessMessage = true;
          this.showForm = false;
          this.separateRule = false;
        } else if (response.data.status == "error") {
          this.errorMessage = response.data.message;
          this.showErrorMessage = true;
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    handleToLogin() {
      this.isLoadingBackToLogin = true;
      window.location.href = this.$hostname + "/login";
    },
    errorRedirect500(error) {
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
  },
};
</script>
